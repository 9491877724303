<template>
	<div class="enterpriseChoice">
		<div class="alls">
			<div class="title-all">类型选择</div>
			<van-radio-group v-model="radio">
				<van-cell-group v-for="(item,index) in typeList" :key="index">
					<van-cell :title="index+1+'&nbsp;&nbsp;'+item.value" clickable @click="typeE(index)">
						<template #right-icon>
							<van-radio :name="index" />
						</template>
					</van-cell>
				</van-cell-group>
			</van-radio-group>
			<div class="submit-type">
				<van-button block round type="info" color="#1989FA" @click="ok()">确定</van-button>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.enterpriseChoice {
		padding: 3%;
	}

	.alls {
		background-color: white;
		width: 94%;
		border-radius: 0.133333rem;
		box-shadow: 0px 0px 8px #4098cb;
		padding: 3%;
	}

	.title-all {
		width: 100%;
		text-align: center;
		font-size: 18px;
		font-weight: bold;
		height: 50px;
		line-height: 50px;
	}

	.submit-type {
		width: 90%;
		margin-left: 5%;
		margin-top: 0.933333rem;
		margin-bottom: 0.533333rem;
		left: 0rem;
	}
</style>
<script>
	import enterpriseChoiceJS from "@/js/enterpriseChoice";
	export default {
		...enterpriseChoiceJS,
	};
</script>
