import { getCurrentInstance, reactive, toRefs, ref, } from "vue";
import { useRouter } from 'vue-router'

export default {
	setup(props, context) {

		const { proxy } = getCurrentInstance();
		
		const router = useRouter();

		let dataAll = reactive({
			typeList: ref(null),
			radio: ref(0)
		});

		proxy.$http({
			url: proxy.$http.adornUrl(`/sys/type/getEnterprise`),
			method: 'post',
			data: null,
			headers: {
				token: sessionStorage.getItem("userToken")
			}
		}).then(({
			data
		}) => {
			console.log(data);
			if (data && data.code === 0) {
				dataAll.typeList = data.type;
			} else if (data.code === 401) {
				sessionStorage.setItem("userToken", "");
				router.push({
					path: '/enterpriseJump/' + proxy.$router.currentRoute.value.params.id
				});
			} else {
				proxy.$toast(data.msg);
			}
		});

		const typeE = (val) => { dataAll.radio = val; }

		const ok = () => {
			switch (dataAll.radio) {
				case 0:
					//跳转餐饮
					router.push({
						path: '/enterpriseAddCy/' + proxy.$router.currentRoute.value.params.id
					});
					break;
				case 1:
					//跳转食品生产
					router.push({
						path: '/enterpriseAddSc/' + proxy.$router.currentRoute.value.params.id
					});
					break;
				case 2:
					//跳转食品销售
					router.push({
						path: '/enterpriseAddXs/' + proxy.$router.currentRoute.value.params.id
					});
					break;
				case 3:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 4:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 5:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 6:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 7:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 8:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 9:
					//跳转单体药店
					router.push({
						path: '/enterpriseAddYd/' + proxy.$router.currentRoute.value.params.id
					});
					break;
				case 10:
					//跳转连锁药店
					router.push({
						path: '/enterpriseAddYdL/' + proxy.$router.currentRoute.value.params.id
					});
					break;
				case 11:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 12:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 13:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 14:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 15:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 16:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 17:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 18:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 19:
					proxy.$toast({
						message: '当前类型暂不支持，手机添加！',
						icon: "question"
					});
					break;
				case 20:
					//跳转特设
					router.push({
						path: '/enterpriseAddTs/' + proxy.$router.currentRoute.value.params.id
					});
					break;
			}
		}

		return {
			...toRefs(dataAll),
			typeE,
			ok
		}
	}
}
